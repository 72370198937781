<template>
  <div style="padding-left: 25px; padding-right: 25px;">
    <h1>Client Apps Management</h1>
    <div style="display: flex; justify-content: space-between;">
      <el-button round @click="goToHomeView">Home</el-button>
      <el-button v-if="hasSysAdminPermission" round type="primary" @click="registerDialogVisible = true">Add</el-button>
    </div>
    <div v-if="hasSysAdminPermission" v-loading="pageLoading">
      <el-table
        :data="clientApps"
        style="width: 100%">
        <el-table-column
          prop="name"
          label="Name">
        </el-table-column>
        <el-table-column
          prop="organizationId"
          label="Organization ID">
        </el-table-column>
        <el-table-column
          prop="apiKey"
          label="API Key">
        </el-table-column>
        <el-table-column
          prop="signingPublicKey"
          label="Signing Public Key">
        </el-table-column>
        <el-table-column
          prop="signingKeyAlg"
          label="Signing Key Algorithm">
        </el-table-column>
        <el-table-column
          prop="active"
          label="Status">
          <template v-slot="scope">
            <div>{{ scope.row.active ? 'Activated' : 'Deactivated' }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="Operations">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.active"
              size="mini"
              type="danger"
              plain
              @click="() => deactivateConfirmation (scope.row)">Deactivate
            </el-button>
            <el-button
              v-else
              size="mini"
              type="success"
              plain
              @click="() => activateConfirmation (scope.row)">Activate
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="padding-top: 10px; display: flex; justify-content: flex-end;">
        <el-pagination background @size-change="handlePageSizeChange" @current-change="handlePageNumberChange"
          :page-size.sync="clientAppsPagination.pageSize" :page-sizes="[5, 10, 20, 50, 100]"
          :current-page="clientAppsPagination.pageNumber" layout="total, sizes, prev, pager, next, jumper"
          :total="clientAppsPagination.total">
        </el-pagination>
      </div>
    </div>
    <div v-else>
      <div>Unauthorized</div>
    </div>
    <el-dialog :visible.sync="registerDialogVisible">
      <client-app-register v-bind:registerDialogVisible.sync="registerDialogVisible" @registered="handleClientAppRegistered"></client-app-register>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ClientAppRegister from './components/register.vue'

export default {
  name: "clientApp",
  components: { ClientAppRegister },
  data() {
    return {
      pageLoading: false,
      clientApps: [],
      clientAppsPagination: {
        pageSize: 20,
        pageNumber: 1,
        total: null
      },
      registerDialogVisible: false
    };
  },
  computed: {
    ...mapState(["permissions"]),
    hasSysAdminPermission() {
      return this.permissions.hasSysAdminPermission;
    },
  },
  mounted() {
    if (!this.hasSysAdminPermission) return
    this.getClientApps()
  },
  methods: {
    async getClientApps () {
      const res = await this.$request.get({
        url: `${this.$apis.clientApps}?pageNumber=${this.clientAppsPagination.pageNumber - 1}&pageSize=${this.clientAppsPagination.pageSize}&offline=false`
      })

      if (res.code === 1000) {
        this.clientApps = res.data?.content || []
        this.clientAppsPagination.pageSize = res.data?.size || 20
        this.clientAppsPagination.pageNumber = res.data?.number + 1 || 1
        this.clientAppsPagination.total = res.data?.totalElements
      }
    },
    async addClientApp () {

    },
    handlePageSizeChange(size) {
      this.clientAppsPagination.pageSize = size
      this.getClientApps()
    },
    handlePageNumberChange(number) {
      this.clientAppsPagination.pageNumber = number
      this.getClientApps()
    },
    handleClientAppRegistered () {
      this.getClientApps()
    },
    goToHomeView () {
      this.$router.push({ name: 'Welcome'})
    },
    async updateClientAppStatus (id, status) {
      if (!id) return
      this.pageLoading = true
      try {
          const res = await this.$request.post({
            url: `${this.$apis.clientApps}/${id}/${ status ? 'activate' : 'deactivate' }`,
            data: this.formData
          })

          this.getClientApps()
        }
        catch (e) {
          this.$message({
              type: "error",
              message: "Error Updating Activation Status",
            });
        }
        finally {
          this.pageLoading = false
        }
    },
    async activateConfirmation(app) {
      this.$confirm(
        `Confirm to activate the client application (${app.name}) ?`,
        "Activate Client Application",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      )
        .then(async () => {
          await this.updateClientAppStatus(app.id, true);
          this.$message({
            type: "success",
            message: "Activated",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Canceled",
          });
        });
    },
    async deactivateConfirmation(app) {
      this.$confirm(
        `Confirm to deactivate the client application (${app.name}) ?`,
        "Deactivate Client Application",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      )
        .then(async () => {
          await this.updateClientAppStatus(app.id, false);
          this.$message({
            type: "success",
            message: "Deactivated",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Canceled",
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
